import { Vue, Component } from "vue-property-decorator";
import { EventMixin } from "@/views/Admin/Event/Dependencies/Mixins/EventMixin";
import { TabItemEntity } from "@/models/Core/Tab";
import { getTabItems } from "@/models/events/Tab";
import { EventEntity } from "@/models/events/Event";
import { ConfigFields, ValidationRules } from "@/models/events/RuleBuilder";
import { ResourceItem } from "@/interfaces/event";
import { TypeLoading } from "@/interfaces/loading";
import { DataFieldEntity } from "@/models/events/Field";
import { Getter } from "vuex-class";

@Component
export default class EventCreate extends Vue.extend(EventMixin) {
	// Declarar explícitamente las propiedades del mixin.
	public eventData!: EventEntity;
	public currentTab!: number;
	public items: TabItemEntity[] = getTabItems(false);
	public rules!: ValidationRules;
	public configFields!: ConfigFields;

	@Getter("events/storeDataFields") dataFields!: DataFieldEntity;

	public get hasEventID() {
		return Number(this.eventData.id) > 0;
	}

	public get getFilteredItems() {
		const id = this.eventData?.event_attribution_id;
		if (!id) return this.items;
		const isOnlyOnjects = this.dataFields.isOnlyOnjects(id);
		const isDisabled = !this.hasEventID || !isOnlyOnjects;
		return getTabItems(!isDisabled);
	}

	// Declarar explícitamente los métodos del mixin.
	public handleTabIndex!: (tabIndex: number) => void;
	public currentTabSelected!: (key: number) => boolean;
	public dispatchStore!: <T>(moduleFunc: string, data: T) => Promise<any>;
	public onEventDataChange!: (newVal: string, oldVal: string) => void;
	public handleUpdate!: (params: {
		type: string;
		key: string;
		value: any;
	}) => void;

	// Método para observacion de eventos
	public handleChange<T>(params: {
		key: string;
		value: ResourceItem[];
		selected: number | null;
	}): void {}

	// Método para establecer el estado de carga utilizando Vuex
	private async setLoadingData(actionType?: TypeLoading) {
		await this.dispatchStore("loading/setLoadingData", actionType);
	}

	// Vue lifecycle hooks
	private created() {
		this.dataFields = new DataFieldEntity();
	}

	private mounted() {
		this.$nextTick(() => {});
	}

	private beforeDestroy() {
		this.dataFields.tag.setData("generated", "");
	}

	private destroyed() {
		this.dataFields.tag.setData("generated", "");
	}
}
